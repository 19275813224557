export const SupportedLanguages: LanguageType[] = [
    {
        "name": "Albanian",
        "code": "sq"
    },
    {
        "name": "Arabic",
        "code": "ar"
    },
    {
        "name": "Belarusian",
        "code": "be"
    },
    {
        "name": "Bosnian",
        "code": "bs"
    },
    {
        "name": "Bulgarian",
        "code": "bg"
    },
    {
        "name": "Catalan",
        "code": "ca"
    },
    {
        "name": "Chinese (Simplified)",
        "code": "zh-CN"
    },
    {
        "name": "Chinese (Traditional)",
        "code": "zh-TW"
    },
    {
        "name": "Croatian",
        "code": "hr"
    },
    {
        "name": "Czech",
        "code": "cs"
    },
    {
        "name": "Danish",
        "code": "da"
    },
    {
        "name": "Dutch",
        "code": "nl"
    },
    {
        "name": "English",
        "code": "en"
    },
    {
        "name": "Finnish",
        "code": "fi"
    },
    {
        "name": "French",
        "code": "fr"
    },
    {
        "name": "Galician",
        "code": "gl"
    },
    {
        "name": "Georgian",
        "code": "ka"
    },
    {
        "name": "German",
        "code": "de"
    },
    {
        "name": "Greek",
        "code": "el"
    },
    {
        "name": "Hebrew",
        "code": "he"
    },
    {
        "name": "Hungarian",
        "code": "hu"
    },
    {
        "name": "Icelandic",
        "code": "is"
    },
    {
        "name": "Indonesian",
        "code": "id"
    },
    {
        "name": "Italian",
        "code": "it"
    },
    {
        "name": "Japanese",
        "code": "ja"
    },
    {
        "name": "Khmer",
        "code": "km"
    },
    {
        "name": "Korean",
        "code": "ko"
    },
    {
        "name": "Lithuanian",
        "code": "lt"
    },
    {
        "name": "Macedonian",
        "code": "mk"
    },
    {
        "name": "Malay",
        "code": "ms"
    },
    {
        "name": "Nepali",
        "code": "ne"
    },
    {
        "name": "Persian",
        "code": "fa"
    },
    {
        "name": "Polish",
        "code": "pl"
    },
    {
        "name": "Portuguese (Portugal)",
        "code": "pt"
    },
    {
        "name": "Portuguese (Brazil)",
        "code": "pt-BR"
    },
    {
        "name": "Romanian",
        "code": "ro"
    },
    {
        "name": "Russian",
        "code": "ru"
    },
    {
        "name": "Serbian",
        "code": "sr"
    },
    {
        "name": "Slovak",
        "code": "sk"
    },
    {
        "name": "Slovenian",
        "code": "sl"
    },
    {
        "name": "Spanish",
        "code": "es"
    },
    {
        "name": "Swedish",
        "code": "sv"
    },
    {
        "name": "Turkish",
        "code": "tr"
    },
    {
        "name": "Turkmen",
        "code": "tk"
    },
    {
        "name": "Ukrainian",
        "code": "uk"
    },
    {
        "name": "Vietnamese",
        "code": "vi"
    },
    {
        "name": "Welsh",
        "code": "cy"
    }
];

export declare type SystemLanguageType  = {
    name: string,
    code: string,
    default: boolean|any,
    published: boolean|any,
    created_at: string,
    updated_at: string,
    translations: any
};

export declare type LanguageType  = {
    name: string,
    code: string
};

export const RTLLanguagesCodes = [
    'ar',
    'arc',
    'dv',
    'fa',
    'ha',
    'he',
    'khw',
    'ks',
    'ku',
    'ps',
    'ur',
    'yi'
]