import { Injectable } from '@angular/core';
import { Camera, CameraResultType } from '@capacitor/camera';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor() { }

  async takeProfilePicture(){
    return Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType:  CameraResultType.DataUrl,
      promptLabelHeader: 'Take photo from: ',
      promptLabelPhoto: 'Photo albums',
      promptLabelPicture: 'Camera'
    });
  }
  
}
