import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    public toastController: ToastController,
    private translate: TranslateService
  ) { }

  async message(message: string = '', color: string = 'light', position: 'top'|'middle'|'bottom' = 'bottom'){
    const toast = await this.toastController.create({
      message,
      position,
      color,
      buttons: ['OK']
    });
    await toast.present();
  }

  async error(message: string = '', color: string = 'danger'){
    const toast = await this.toastController.create({
      message: message || this.translate.instant('went_wrong'),
      position: 'bottom',
      color,
      buttons: ['OK']
    });
    await toast.present();
  }

  async success(message: string = '', color: string = 'success'){
    const toast = await this.toastController.create({
      message: message || this.translate.instant('actions.operation_executed'),
      position: 'bottom',
      color,
      buttons: ['OK']
    });
    await toast.present();
  }
}
