import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../constants';
/**
 * This pipe take a role id or name as value and an array of user's roles as 
 * roles and return true if the role exists in the array
 */
@Pipe({
  standalone: true,
  name: 'hasRole'
})
export class HasRolePipe implements PipeTransform {

  transform(value: number|string, roles: Role[] = []): boolean {
    if (!roles.length) return false;
    
    return !!roles.find((role) => role.name === value || role.id === value);
  }

}
