import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export type GenericResponse<T = void|any> = {
  success: boolean,
  message: string,
  value: T
}

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    private http: HttpClient
  ) { }

   //Main Service Methods for GET, POST, PUT, PATCH & DELETE
   public get(apiURL: string, reqInput?: any): Observable<any> {
    const paramInputs = { params: reqInput ? reqInput : undefined };
    return this.http.get<Observable<any>>(apiURL, paramInputs);
  }

  public post(apiURL: string, body: any): Observable<any> {
    let headers = {};
    if (!(body instanceof FormData)) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }
    const httpOptions = { headers };
    return this.http.post<Observable<any>>(apiURL, body, httpOptions);
  }

  public put(apiURL: string, body: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.put<Observable<any>>(apiURL, body, httpOptions);
  }

  public patch(apiURL: string, body: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.patch<Observable<any>>(apiURL, body, httpOptions);
  }

  public delete(apiURL: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.delete<Observable<any>>(apiURL, httpOptions);
  }

  public deleteWithBody(apiURL: string, body: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body};
    return this.http.delete<Observable<any>>(apiURL, httpOptions);
  }
}
