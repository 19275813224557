import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../auth/storage/storage.service';
import { establishWebSocketConnection } from '../../utils/websocket-connection';

@Injectable({
  providedIn: 'root'
})

export class WebSocketService {
  echo!: any;
  private _incomingSupportMessage = new BehaviorSubject<any>([]);
  private _outgoingSupportMessage = new BehaviorSubject<any>([]);

  private _incomingGroupMessage = new BehaviorSubject<any>([]);
  private _outgoingGroupMessage = new BehaviorSubject<any>([]);

  private _incomingTeamMessage = new BehaviorSubject<any>([]);
  private _outgoingTeamMessage = new BehaviorSubject<any>([]);

  private _incomingCoachingMessage = new BehaviorSubject<any>([]);
  private _outgoingCoachingMessage = new BehaviorSubject<any>([]);

  private _supportQueryItem = new BehaviorSubject<any>([]);

  private _updateConnectBadge = new BehaviorSubject<{type: string, id: number}>({type: 'init', id: 0});

  private _incomingActivity = new BehaviorSubject<any>([]);

  constructor(
    private authStorageService: StorageService,
    
  ) { 
    this.echo = establishWebSocketConnection();
  }

  // establishWebSocketConnection() {
  //   const pusherKey = 'local';
  //   const webSocketHost = environment.webSocketHost;
  //   var pusherData: any = {
  //     cluster: 'mt1',
  //     wsHost: webSocketHost,
  //     disableStats: false,
  //     forceTLS: true,
  //     encrypted: true,
  //     scheme: 'https',
  //     enabledTransports: ["ws", "wss"]
  //   };
  //   if (webSocketHost == '127.0.0.1') {
  //     pusherData.wsPort = 6001;
  //     pusherData.wssPort = 6001;
  //     pusherData.forceTLS = false;
  //     pusherData.encrypted = false;
  //     pusherData.scheme = 'http';
  //   }
  //   const pusher = new Pusher(pusherKey, pusherData);

  //   this.echo = new Echo({
  //     broadcaster: 'pusher',
  //     client: pusher,
  //     forceTLS: false,
  //   });
  // }

  updateConnectBadge() {
    return this._updateConnectBadge.asObservable();
  }

  // Support listeners
  // listenToIncomingSupportMessage() {
  //   // let receiverId: number = Number(localStorage.getItem('userId'));
  //   let userId = this.authStorageService.user?.id as number;

  //   this.echo.channel('chat-room').listen('.button.clicked', (e: any) => {
  //     this._incomingSupportMessage.next(e);
  //     console.log({e})
  //   }).error((error: any) => {
  //     console.error('Error in WebSocket communication:', error);
  //   });
  // }

  listenToIncomingSupportMessage() {
    let receiverId: number = this.authStorageService.user?.id as number;

    this.echo.channel('chat-room-incoming-message-support-'+receiverId).listen('.message-received', (e: any) => {
      this._incomingSupportMessage.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  newSupportIncomingMessage() {
    return this._incomingSupportMessage.asObservable();
  }

  newIncomingActivity()
  {
    return this._incomingActivity.asObservable();
  }

  // activities
  listenToIncomingActivity() {
    let userID: number = this.authStorageService.user?.id as number;
    this.echo.channel('activity-channel.'+userID).listen('.prayforall-activities', (e: any) => {
      this._incomingActivity.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  registerWebSocketListeners() {
    // this.establishWebSocketConnection();

    this.listenToIncomingSupportMessage();

    this.listenToIncomingActivity();

  }

  // support
  newSupportItem() {
    return this._supportQueryItem.asObservable();
  }

  newSupportOutgoingMessage() {
    return this._outgoingSupportMessage.asObservable();
  }

  listenToOutgoingSupportMessage(chatRoomId: number) {
    let senderId: number = this.authStorageService.user?.id as number;

    this.echo.channel('chat-room-outgoing-message-support-'+ chatRoomId +'-'+senderId).listen('.button.clicked', (e: any) => {
      console.log(e);
      this._outgoingSupportMessage.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }

  listenToRealtimeSupportQueries() { // Listen if a new support query is open
    let receiverId: number = Number(localStorage.getItem('userId'));

    this.echo.channel('realtime-support-queries-update-'+ receiverId).listen('SupportQueryUpdate', (e: any) => {
      this._supportQueryItem.next(e);
    }).error((error: any) => {
      console.error('Error in WebSocket communication:', error);
    });
  }


  

}