import { Injectable, NgZone } from '@angular/core';
import { FirebaseMessaging, GetTokenOptions, NotificationActionPerformedEvent, NotificationReceivedEvent } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { normalizeUrl } from '../../helpers';
import { ShareDataService } from '../share-data/share-data.service';
import { initializeApp } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  token?: string;

  constructor(private toastController: ToastController, private router: Router, private ngZone: NgZone, private shareData: ShareDataService) {
    
    if (!Capacitor.isNativePlatform()) {
      initializeApp(environment.firebase);
    }
  }
  
  async addListeners(){
    await FirebaseMessaging.addListener("notificationReceived", (event: NotificationReceivedEvent) => {
      // console.log("notificationReceived: ", { event });
      this.shareData.hasNotification(true);
    });
    await FirebaseMessaging.addListener("notificationActionPerformed", (event: NotificationActionPerformedEvent) => {
      const data: any = event.notification.data;
      // console.log("notificationActionPerformed: ", { event });
      let url = normalizeUrl(data?.url);
      this.ngZone.run(
        async () => await this.router.navigateByUrl(url)
      );
    });
    
    if (Capacitor.getPlatform() === 'web') {
      navigator.serviceWorker.addEventListener('message', async (event: MessageEvent) => {
        this.shareData.hasNotification(true);
        const { notification: receivedNotification, data, messageType } = event.data;
        if (data['gcm.notification.data']) {
          let dataValue = data['gcm.notification.data'];
          receivedNotification.data = typeof dataValue === 'string' ? JSON.parse(dataValue) : dataValue;
        } else {
          receivedNotification.data = {};
        }

        let notificationUrl: string = receivedNotification.data?.url;
        
        if (messageType === 'notification-clicked') {
          // direct redirect
            let url = notificationUrl ? normalizeUrl(notificationUrl) : null;
            this.ngZone.run(
              async () => url && await this.router.navigateByUrl(url)
            );
        } else if (messageType === 'push-received') {
          // show notification
          const notification = new Notification(receivedNotification.title, {
            body: receivedNotification.body,
            icon: '/assets/icon/favicon.png',
            data: receivedNotification?.data || undefined,
          });
          
          notification.onclick = (event) => {
            let url = notificationUrl ? normalizeUrl(notificationUrl) : null;
            this.ngZone.run(
              async () => url && await this.router.navigateByUrl(url)
            );
          }
        }
      });
    }
  }
  
  async registerNotifications(): Promise<string|false> {
    return new Promise(
      async (resolve) => {
        const permStatus = await FirebaseMessaging.checkPermissions();
        
        if (permStatus.receive != 'denied') {
          FirebaseMessaging.requestPermissions().then(async (result) => {
            if (result.receive === 'granted') {
              const options: GetTokenOptions = {
                vapidKey: environment.firebase.vapidKey,
              };
              
              if (Capacitor.getPlatform() === 'web') {
                options.serviceWorkerRegistration = await navigator.serviceWorker.register('firebase-messaging-sw.js');
                await navigator.serviceWorker.ready;
              }
              
              const { token } = await FirebaseMessaging.getToken(options);
              this.token = token;
              localStorage.setItem('firebaseToken', this.token);
              // console.log(this.token);
              resolve(token);
            } else {
              const toast = await this.toastController.create({
                message: 'As you did not give permission, you\'ll not be able to receive push notifications',
                icon: 'notifications-off-outline',
                buttons: ['Ok']
              });
              await toast.present();
              resolve(false);
            }
          }, (err) => resolve(false));
        } else {
          const toast = await this.toastController.create({
            message: 'As you did not give permission, you\'ll not be able to receive push notifications',
            icon: 'notifications-off-outline',
            buttons: ['Ok']
          });
          await toast.present();
          resolve(false);
        }
      }
    )
  }

  static async unregisterNotifications(): Promise<void> {
    return new Promise(
      async (resolve) => {
        try {
          await FirebaseMessaging.removeAllListeners();
          await FirebaseMessaging.deleteToken();
        } catch (error) {
          console.log({error})
        } finally {
          resolve();
        }
      }
    )
  }
  
  async getDeliveredNotifications() {
    const notificationList = await FirebaseMessaging.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  }
  
}
