import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mediaType',
  standalone: true
})
export class MediaTypePipe implements PipeTransform {

  transform(value: string|null, type?: 'image'|'video'): boolean|'video'|'image' {
    if (!value) return false;
    
    if (type) {
      return type === 'video' ? /.*\.mp4$/ig.test(value) : /.*\.(jpg|png|svg|jpeg)$/ig.test(value);
    }
    return /.*\.mp4$/ig.test(value) ? 'video' : (/.*\.(jpg|png|svg|jpeg)$/ig.test(value) ? 'image' : false);
  }

}
