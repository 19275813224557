import { Injectable } from '@angular/core';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { UserType } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public isAuthenticated() {
    return localStorage.getItem('world_prays_token');
  }

  public clear(){
    return localStorage.clear();
  }

  public get shouldRefreshToken() {
    const tokenIssuedDate = localStorage.getItem('token_issued_date') as string;
    const tokenExpirationDate = localStorage.getItem('token_expiration_date') as string;
    if (tokenIssuedDate) {
      const oldDate = parseISO(tokenIssuedDate);
      const expirationDate = parseISO(tokenExpirationDate || new Date().toISOString());
      const days = differenceInCalendarDays(expirationDate, oldDate);
      return days <= 2;
    }
    return false;
  }

  public setUser(user: UserType) {
    if (user.id) {
      localStorage.setItem('world_prays_user', JSON.stringify(user));
    }
  }

  public get user() {
    const user: UserType = JSON.parse(localStorage.getItem('world_prays_user') as string);
    if (user?.id) {
      return user;
    }
    return undefined;
  }

  public get organization() {
    if (this.user && this.user.organization) {
      return this.user.organization;
    }
    return undefined;
  }

}
