import { MediaType } from "plyr";
import { APIROUTES } from "./api-end-points";
import { PasswordStrength, monthDates, monthsOfTheYear, hourMarkType, hourMarks } from './selection-categories'

export type OrganizationType = {
    id: number,
    pmd_id?: number,
    organization_type_id: number,
    city_id: number,
    name: string,
    email?: string,
    website?: string,
    phone_country_code?: string,
    phone_number?: string|number,
    short_bio?: string,
    logo?: string,
    status?: string,
    created_at?: string,
    updated_at?: string,
    created_by?: number,
    logo_url?: string
}
export type PrayerMediaType = {
    id?: number,
    media: string,
    type: MediaType|'image'
};

export type PrayerJournal = {
    id: number,
    content: string,
    user_id: number,
    updated_at: string,
    created_at: string,
};

export type PrayerNews = {
    id: number,
    user_id: number,
    content: string,
    city_id: number,
    language_code: string,
    status: string,
    is_reported: boolean,
    report_reason: string|null,
    reported_by: number|null,
    reported_at: string|null,
    report_approved_by: number|null,
    report_approved_at: string|null,
    created_at: string,
    updated_at: string,
    likeCount: number,
    isLikedByUser: boolean,
    translated?: boolean,
    user: {
        id: number,
        name: string,
        surname: string,
        profile_picture_url: string|null,
        roles_list: Role[]|[],
        permissions_list: RolePermission[]|[]
    },
    city: {
        id: number,
        name: string,
        country_code: string
    }
};

export type PrayerBoard = {
    id: number,
    city_id: number,
    organization_id: null|number,
    pmd_id: null|number,
    created_by: {
        id: number,
        name: string,
        surname: string,
        profile_picture_url: string|null
    },
    language_code: string,
    title: string,
    content: string,
    prayer_point: string,
    link: string,
    prayer_media_list: PrayerMediaType[],
    photo: string|null,
    photo_url: string|null,
    status: string,
    created_at: string,
    updated_at: string,
    organization: null|{
        id: number,
        name: string
    },
    city: null|{
        id: number,
        name: string,
        country_code: string
    },
    pmd: null|{
        id: number,
        name: string
    },
    commentsCount: number,
    isJoinedByUser: boolean,
    isLikedByUser: boolean,
    likeCount: number,
    joinCount: number,
    seenCount?: number,
    seenPercent?: number,
    prayer_point_list: PrayerPoint[],
} & Record<string, any>;

export type PrayerBoardComment = {
    id: number,
    content: string,
    prayer_board_id: number,
    user_id: number,
    is_reported?: boolean,
    updated_at: string,
    created_at: string,
    user: {
        id: number,
        name: string,
        surname: string,
        email: string,
        profile_picture: string|null,
        city_id: number,
        profile_picture_url: string|null,
        city: {
            id: number,
            country_code: string,
            name: string,
            country: {
                id: number,
                code: string,
                name: string
            }
        }
    }
} & Record<string, any>;

export type Role = {
    id: number,
    name: string,
    level : number;
    display_name: string
};

export type RolePermission = Role;

export type UserType = {
    id: number,
    user_type_id: number,
    preferred_language_id: number,
    city_id: number,
    organization_id?: number,
    selected_pmd_id?: number,
    name: string,
    surname: string,
    email: string,
    email_verified_at: string|null,
    dob: string,
    phone_country_code: string,
    mobile_phone: string,
    profile_picture: string|null,
    created_by: number|null,
    is_admin: boolean,
    status: string,
    created_at: string,
    updated_at: string,
    profile_picture_url: string|null,
    city: {
        id: number,
        country_code: string,
        name: string,
        created_at: string|null,
        updated_at: string|null,
        country: {
            id: number,
            name: string,
            code: string,
            phone_code: string,
            created_at: string,
            updated_at: string
        }
    },
    organization: null|OrganizationType,
    selected_pmd: null|{
        id: number,
        name: string,
        logo_url?: string
    },
    type?: {
        id: number,
        name: string,
        code: 'organization'|'pmd'|'individual',
        status: string,
        created_at: string,
        updated_at: string
    },
    preferred_language: {
        id: number,
        code: string,
        name: string,
        status: string,
        created_at: null|string,
        updated_at: null|string
    },
    roles_list: Role[],
    permissions_list: RolePermission[],
    countPrayer: boolean
} & Record<string, any>;

export type PrayerCard = {
    id: number,
    city_id: number,
    title: string,
    content: string,
    photo: string|null,
    logo: string|null,
    logo_url: string|null,
    photo_url: string|null,
    created_by: {
        id: number,
        name: string,
        surname: string,
        profile_picture_url: string|null
    },
    city: null|{
        id: number,
        name: string,
        country_code: string,
        country: {
            id: number,
            name: string,
            code: string,
            phone_code: string
        }
    },
    status: string,
    created_at: null|string,
    updated_at: null|string,
    is_prayforall: number
};

export type PrayerPoint = {
    id: number,
    content: string
};

export type PrayerVideo = {
    id: number,
    title: string,
    video_link: string,
    language_id: number,
    language: {
        id: number,
        name: string,
        code: string,
    },
    created_by: {
        id: number,
        name: string,
        surname: string,
        profile_picture_url: string|null
    },
    status: string,
    created_at: null|string,
    updated_at: null|string,
    is_prayforall: number
};

export type VipPerson = {
    id: number,
    user_id: number,
    name: string,
    avatar_id : number,
    is_saved : number,
    status : string,
    created_at: null|string,
    updated_at: null|string,
    relationship_id : number,
    relationship_name: string,
    profile_picture_url: string,
    photo : string,
    rank: number,
    user: {
        id: number,
        name: string,
        surname: string,
        email: string,
        profile_picture: string|null,
        city_id: number,
        profile_picture_url: string|null,
        city: {
            id: number,
            country_code: string,
            name: string,
            country: {
                id: number,
                code: string,
                name: string
            }
        }
    },
    avatar : {
        id: number,
        name : string,
        created_at: null|string,
        updated_at: null|string
    },
    bless_mission_count : number
}

export type PrayerRoomMeetingStatus = {
    id: number,
    room_id: number,
    user_id: number,
    start_time: string,
    end_time: string,
    status: 'open'|'closed',
    room?: PrayerRoom,
    created_at?: string,
    updated_at?: string,
}

export type PrayerRoom = {
    id?: number,
    title: string,
    about: string,
    codes_of_conduct: string|null,
    profile_picture?: string|null,
    day?: string|number|null,
    week?: number|null,
    month?: number|null,
    year?: number|null,
    start_hour_utc: string,
    next_date_of_meeting: string,
    length?: number|null,
    open_to: 'everyone'|'invitation-only',
    passcode?: string|null,
    member_count?: number|null,
    size?: number|null,
    is_personal: boolean|1|0,
    type: 'one-off'|'recurring',
    is_member?: 0|1|boolean,
    profile_picture_url?: string|null,
    owner: UserType|null,
    organization: OrganizationType|null,
    meetings?: PrayerRoomMeetingStatus[]
} & Record<string, any>

export type Fund = {
    id: number,
    created_by: {
        id: number,
        name: string,
        surname: string,
        profile_picture_url: string|null
    },
    title: string,
    about: string,
    logo: string|null,
    logo_url: string|null,
    owner: UserType|null,
    status: string,
    media: FundMediaType[],
    created_at: null|string,
    updated_at: null|string
};

export type FundMediaType = {
    id?: number,
    // fund_id : number,
    media: string,
    media_url : string,
    // status : string,
    type: MediaType|'image',
    // created_at: null|string,
    // updated_at: null|string
};

export type Transaction = {
    updated_at : null|string,
    title: string,
    payment_type : string,
    reference : string,
    amount : number
}

export type SubscriptionPlan = {
    id: number,
    plan_id: string,
    name: string,
    description: string,
    is_recurring: number,
    type : string,
    number_of_rooms: number,
    price: number,
    size: number,
    length: number,
    length_formated : string,
    status: string,
    created_at: null|string,
    updated_at: null|string
};

export type UserSubscription = {
    id: number,
    created_by: UserType|null,
    plan: SubscriptionPlan,
    start_date: string,
    status: string,
    created_at: null|string,
    updated_at: null|string
};


export { APIROUTES, PasswordStrength, monthDates, monthsOfTheYear, hourMarkType, hourMarks };