import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-modal',
  standalone: true,
  imports: [IonicModule, CommonModule],
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent  implements OnInit {
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() centerText: boolean = true;
  @Input() cancelRole: string = 'cancel';
  @Input() confirmRole: string = 'confirm';
  @Input() cancelText: string = 'Cancel';
  @Input() confirmText: string = 'Confirm';
  @Input() cancelButtonColor: string = 'light';
  @Input() confirmButtonColor: string = 'primary';

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  cancel() {
    this.modalController.dismiss(null, this.cancelRole);
  }

  confirm() {
    this.modalController.dismiss(null, this.confirmRole);
  }
}
