import { Pipe, PipeTransform } from '@angular/core';
import { passwordStrength } from 'check-password-strength';

@Pipe({
  name: 'pwdStrength',
  pure: false,
  standalone: true
})
export class PasswordStrengthPipe implements PipeTransform {

  transform(value: string = ''): string {
    const result = passwordStrength(value, undefined, "!\"#\$%&'\(\)\*\+,-\./:;<=>\?@\[\\\\\\]\^_`\{|\}~")
    return result.value.toLowerCase().replace(' ', '_');
  }

}
