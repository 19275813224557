export const APIROUTES = {
    AUTH: {
        LOGIN: 'auth/login',
        LOGOUT: 'auth/logout',
        ME: 'auth/me',
        PASSWORD: {
            FORGOT: 'forgot-password',
            UPDATE: 'auth/update-password',
            RESET: 'reset-password'
        },
        REFRESH: 'auth/refresh',
        CHECKEMAIL: 'auth/check-email'
    },
    PUSHNOTIFICATION: {
        REGISTER: 'auth/register-firebase-token/', // {user} POST {token}
    },
    LOCATION : {
        COUNTRIES: 'location/countries/', // {offset}
        CITIES: 'location/cities/', //{countryCode}/{offset}
    },
    MASTERDATA: {
        TYPES: 'masterdata',
        LANGUAGES: 'masterdata/preferred_languages',
        USERS: 'masterdata/user_types',
        ORGANIZATIONS: 'masterdata/organization_types',
        
    },
    PROFILE: {
        CREATE: 'profile',
        GET: (user: number) => `profile/${user}`, 
        UPDATE: (user: number) => `profile/${user}`,
        PROFILE: (user: number) => `profile/${user}/profile-picture`,
        CHANGELANGUAGE: `profile/language/update`, // POST {language} // change profil language,
        DEACTIVATEACCOUNT: 'profile/deactivate-account'
    },
    PMD: {
        PROFILE: {
            CREATE: 'pmd-profile',
            GET: (id: number) => `pmd-profile/${id}`, 
            PRESELECT: (id: number) => `select-pmd/${id}`, // get pmd without authentification
            UPDATE: (id: number) => `pmd-profile/${id}`,
            UPDATESTATUS: (pmdId: number, status: string) => `pmd-profile/${pmdId}/update/${status}`,
        },
        LIST: 'pmd-list',
    },
    ORGANIZATION: {
        PROFILE: {
            CREATE: 'organization-profile',
            GET: (id: number) => `organization-profile/${id}`,
            PRESELCT: (id: number) => `select-organization/${id}`, // get organization without authentification
            UPDATE: (id: number) => `organization-profile/${id}`
        },
        LIST: 'organization-list',
        MEMBERS: (organizationId: number) => `summary/${organizationId}/members`,
        USERLIST: 'empower/user/list',
    },
    PRAYERSCHEDULE: {
        CREATE: {
            // ORGANIZATION: 'prayer-schedule/organization',
            ORGANIZATION: 'organization-schedule-prayer',
            // INDIVIDUAL: 'prayer-schedule/individual',
            // ALLOCATEDATEHOUR: 'prayer-schedule/allocateDateHour/', //{userId}
            JOINORGANIZATIONDATE: 'schedule-user-prayer/join-organization-date', //{userId}
            USER_INDIVIDUAL: 'schedule-user-prayer', // POST
            JOINMYOWNDATE: 'schedule-user-prayer/join-myown-date', //{userId}
        },
        GET: 'prayer-schedule/',
        GETALLORGANIZATION: 'organization-schedule-prayer/allorganization/',
        GET_ORGANIZATION_SCHEDULE: 'organization-schedule-prayer/',
        // GET_USER_SELECTED_DATE : (userId: number) => `schedule-user-prayer/${userId}/information/selected-date`,
        GET_USER_SELECTED_DATE : (orgId: number,userId: number) => `schedule-user-prayer/${orgId}/${userId}/information/selected-date`,
        // ORG_TIMESPAN: 'prayer-schedule/organization/details/timespan',
        ORG_TIMESPAN: 'organization-schedule-prayer/organization/details/date',
        // TIMESPAN: 'prayer-schedule/timespan',
        // ALLOCATEDATEHOURDETAILS: (userId: number) => `prayer-schedule/allocateDateHour/${userId}/details`,
        GET_INDIVIDUALS_OWN_DATE : 'schedule-user-prayer/',
        GET_NEXT_PRAYER_DAY : 'prayer/list-some-next-prayer-day'
        
    },
    PRAYERBOARD: {
        LIST: 'prayerboard/list/', // GET {action} seen or unseen,
        LISTAPPROVED: 'prayerboard/management', // GET ,
        LISTANY: 'prayerboard', // GET
        CREATE: 'prayerboard', // POST
        PERFORM_ACTION: (prayerId: number, action: string) => `prayerboard/handleprayer/${prayerId}/${action}`,
        DETAILS: 'prayerboard/', // GET {prayerboard}
        UPDATE: 'prayerboard/', // {prayerboard} PUT
        PENDING: 'prayerboard/pending/list',
        APPROVE: (prayerboard: number) => `prayerboard/pending/${prayerboard}/approve/update`,
        REJECT: (prayerboard: number) => `prayerboard/pending/${prayerboard}/reject/update`,
        COMMENTS: {
            ADD: (prayerboard: number) => `prayerboard/${prayerboard}/comment`, // POST {content}
            LIST: (prayerboard: number) => `prayerboard/${prayerboard}/comment`, // GET
            UPDATE: (prayerboard: number, prayercardcomment: number) => `prayerboard/${prayerboard}/comment/${prayercardcomment}`, // PATCH {content}
            REMOVE: (prayerboard: number, prayercardcomment: number) => `prayerboard/${prayerboard}/comment/${prayercardcomment}`, // DELETE
            REPORT: (prayerboard: number) => `prayerboard/${prayerboard}/comment/report`, // POST {"reason", "prayer_board_comment_id"}
            LIST_REPORTED: (prayerboard: number) => `prayerboard/${prayerboard}/comment/report`, // GET
        }
    },
    GLOBALPRAYER: {
        PRAYER: {
            PRAYERTIME: 'prayer/next-prayer-day',
            PRAYACTION: 'prayer/logprayer/', // {action = start|stop} POST {date}
        },
        CARDS: {
            LIST: 'prayercard',
            CREATE: 'prayercard', // POST
            GET: 'prayercard/', // {prayercard}
            UPDATE: 'prayercard/', // {prayercard} PUT
            UPDATE_STATUS: (prayercard: number) => `prayercard/${prayercard}/status/update`
        },
        PRAYERROOM: {
            LIST: 'prayernew',
            CREATE: 'prayernew', // POST {content}
            GET: 'prayernew/', // {prayernew}
            UPDATE: 'prayernew/', // {prayernew} PUT {content}
            DELETE: 'prayernew/', // {prayernew} DELETE
            REACT: 'prayernew/handleprayer/', // {prayernew}/{action} POST
            REPORT: 'prayernew/report/', // {prayernew} POST {report_reason}
            APPROVEREPORT: (prayernew: number) => `prayernew/handleprayer/${prayernew}/approved`, // {prayernew}/{action} POST 
        },
        VIDEOS: {
            LIST: 'prayervideo',
            CREATE: 'prayervideo', // POST
            GET: 'prayervideo/', // {prayervideo}
            UPDATE: 'prayervideo/', // {prayervideo} PUT
            DETAILS: 'prayervideo/', // GET {prayervideo}
            UPDATE_STATUS: (prayervideo: number, status: string) => `prayervideo/${prayervideo}/update/${status}`
        },
        JOURNAL: {
            LIST: 'prayerjournal',
            CREATE: 'prayerjournal', // POST {content}
            UPDATE: 'prayerjournal/', // {prayerjournal} PUT {content}
            DELETE: 'prayerjournal/', // {prayerjournal} DELETE
        }
    },
    PRAYERREPORT: {
        USER: {
            COVERED: 'report/user/prayer-covered'
        },
        ORGANIZATION: {
            COVERED: 'report/organization/hour-covered',
            SUCCESSPRAYER: 'report/organization/success-prayer',
            GETPRAYERBYMONTH: (organizationId: number) => `prayer-schedule/${organizationId}`,
            GETORGANIZATIONPRAYERBYMONTH: (organizationId: number) => `organization-schedule-prayer/${organizationId}`// organization-schedule-prayer // new end point
        },
        CITY: {
            REPORT: `report/city-director`
        },
        PMD: {
            REPORT: 'report/pmd/', // GET {pmd} QUERY {month, year}
            REPORT_LOCATION: (pmd: number) => `report/pmd/locations/${pmd}`,
            REPORT_DATA: (pmd: number) => `report/pmd/${pmd}`,
        }
    },
    EMPOWERMENT: {
        PERMISSIONS: 'rolepermission/permissions',
        ROLES: 'rolepermission/roles',
        FILTEREDROLES: 'rolepermission/filtered-roles',
        PERMISSIONSBYROLE: (role: number) => `rolepermission/permissions/${role}`,
        ROLEBYPERMISSION: (permission: number) => `rolepermission/roles/${permission}`,
        USERROLE: 'empower/user',
        USERPERMISSION: 'empower/user/permissions',
        SEARCHUSER: 'empower/user/search', // POST {name}
    },
    SUPPORT: {
        LIST: 'support/list/', // support/list/{offset}/{user?}
        CREATE: 'support/create/', // support/create/{user}
        DETAILS: 'support/details/', // support/details/{support}/{user?}
        LOAD_MESSAGES: 'support/messages/', // support/messages/{support}/{offset?}
        SEND_MESSAGE: 'support/message/', // support/message/{user}/{support}
        MARK_AS: 'support/mark_as/', // support/mark_as/{support}
    },

    // LOCALIZATION - BACKOFFICE
    LANGUAGE: {
        LOAD: 'language/load/', // {language}
        LOADALL: 'language/get',
        GETLIST: 'language/list', // {language?}
        NEW: 'language/create', // POST {name, code}
        SAVE: 'language/save/', // {language} POST {translations}
        PUBLISH: 'language/publish/', // {language} PUT
        UNPUBLISH: 'language/unpublish/', // {language} DELETE
    },

    // PRAYER CARD
    PRAYERCARD: {
        LIST: 'prayercard', // GET {action} seen or unseen,
        CREATE: 'prayercard', // POST
        // PERFORM_ACTION: (prayerId: number, action: string) => `prayerboard/handleprayer/${prayerId}/${action}`,
        // DETAILS: 'prayerboard/', // GET {prayerboard}
        UPDATE: 'prayercard/', // {prayerboard} PUT
    },
    INVITE: {
        MEMBER: 'invite-member/' // /{type}/{id}
    },

    // SUPERADMIN DASHBOARD
    SUPERADMIN: {
        STATESDETAILS: 'superadmin/stats-details', // GET
        LISTNEWCHURCH: 'superadmin/new-organization-list', // GET
        LISTNEWPMD: 'superadmin/new-pmd-list', // GET
        SUPPORTTICKETLOGS: 'superadmin/support-ticket-logs', // GET,
        COMMUNICATIONCENTERMAIL: 'superadmin/communication-center/sendmailtoall', // POST
        COMMUNICATIONCENTERNOTIFICATION: 'superadmin/communication-center/notifyall', // POST
        PMDDETAILS: (pmd: number) => `superadmin/pmddetails/${pmd}`, // GET
        ORGANIZATIONDETAILS: (organization: number) => `superadmin/organizationdetails/${organization}`,
        USERROLES: (role: number)  =>`rolepermission/user-roles/${role}`,
        COMMUNICATIONCENTER_NOFITYACCOUNTHOLDER: 'superadmin/communication-center/notifyAccountHolder',
        COMMUNICATIONCENTER_MAILACCOUNTHOLDER: 'superadmin/communication-center/mailAccountHolder',
        COMMUNICATIONCENTER_CORRESPONDANCE: 'superadmin/communication-center/correspondance',
        COMMUNICATIONCENTER_NOTIFICATION: 'superadmin/communication-center/notification',
        COMMUNICATIONCENTER_MAIL: 'superadmin/communication-center/mail',
        COMMUNICATIONCENTER_PMDMAIL: (pmd_id: number) => `superadmin/communication-center/pmd_mail/${pmd_id}`,
        COMMUNICATIONCENTER_PMDNOTIFICATION: (pmd_id: number) => `superadmin/communication-center/pmd_notification/${pmd_id}`,
        COMMUNICATIONCENTER_ALLPMD: 'pmd-profile/allpmd/pmds',

        // PLAN
        GLOBAL_PRAYER_ROOM_CREATE_PLAN : 'global_prayer_room/superadmin/create_plan', // POST
        GLOBAL_PRAYER_ROOM_LIST_PLAN : 'global_prayer_room/superadmin/subscription_plan', // GET 
        GLOBAL_PRAYER_ROOM_UPDATE_PLAN : (id : number) => `global_prayer_room/superadmin/update_subrscription_plan/${id}` // PUT 
    },

    // VIP
    VIP: {
        VIP_RELATIONSHIP : 'relationship',
        VIP_AVATAR : 'avatar',
        VIP_PERSON : 'prayforall_vip_persons', // POST/GET 
        VIP_PERSON_BY_ID : (id: number) => `prayforall_vip_persons/${id}`,
        VIP_REORDER_LIST : 'prayforall_vip_persons/reorder', // POST (from and to)
        VIP_UPDATE_SALVATION : (id: number) => `prayforall_vip_persons/${id}/salvation` // PUT
    },

    // FRIEND'S SECTION
    FRIEND_SECTION : {
        FRIEND_REQUEST : 'prayforall/friend-request/search', // GET
        SEND_FRIEND_REQUEST : (id: number) => `prayforall/friend-request/${id}`, // POST
        LIST_NEW_REQUEST_FRIEND : 'prayforall/friend-request', //GET
        LIST_FRIENDS : 'prayforall/friends/search', // GET (USER FRIEND)
        NEW_FRIEND_REQUEST_DETAILS : (request_user_id: number) => `prayforall/friend-request/${request_user_id}/show`, // GET
        FRIEND_REQUEST_ACCEPT : (id: number) => `prayforall/friend-request/${id}/approve`, // GET
        FRIEND_REQUEST_DECLINE : (id : number) => `prayforall/friend-request/${id}/reject`, // GET
        FRIEND_DETAILS : (id : number) => `prayforall/friends/${id}`, // GET
        FRIENDS_STATISTICS : 'prayforall/user/statistics/', // GET
        FRIEND_FRIENDS_ENCOURAGEMENT : 'prayforall/encouragement/list/', // GET
        FRIEND_LIKE_ENCOURAGEMENT : 'prayforall/encouragement/like/', // POST
        FRIEND_UNLIKE_ENCOURAGEMENT : 'prayforall/encouragement/unlike/', // POST
        FRIEND_CREATE_ENCOURAGEMENT : 'prayforall/encouragement', // POST
        UNFRIEND_REQUEST : 'prayforall/friends/unfriend/', // DELETE
    },

    // ALARM - TOP BANNER
    ALARM : {
        SET_DAILY_ALARM : 'prayforall/set-daily-alarm',  // POST
        GET_DAILY_ALARM : 'prayforall/get-daily-alarm', // GET
    },

    // TAB SECTION - PRAYER LOGS - BADGES - ENCOURAGEMENTS
    BADGE : {
        GET_BADGE_USER : (user_id : number) => `prayforall/badge/list/${user_id}` // GET
    },

    ENCOURAGEMET : {
        GET_USER_ENCOURAGEMENT : 'prayforall/encouragement/list/', // GET
        LIKE_ENCOURAGEMENT : 'prayforall/encouragement/like/', // POST
        UNLIKE_ENCOURAGEMENT : 'prayforall/encouragement/unlike/', // POST
    },

    PRAYER_LOG : {
        GET_PRAYER_LOG : 'prayforall/prayer-log' // GET
    },

    PRAY_FOR_ALL : {
        START_PRAYER : 'prayforall/prayer/start-prayer', // POST
        END_PRAYER : 'prayforall/prayer/end-prayer' // POST
    },

    BLESS : {
        GLOBAL_COUNT : 'prayforall/global-count/total-bless-mission-count', // GET
        CREATE_BLESS_MISSION : 'prayforall/blessing-mission/create-bless-mission', // POST
        LIST_CURRENT_BLESS : 'prayforall/blessing-mission/list-of-current-bless-mission', // GET
        LIST_COMPLETED_BLESS : 'prayforall/blessing-mission/list-of-completed-bless-mission', // GET
        COMPLETE_MISSION : 'prayforall/blessing-mission/make-bless-mission-completed/', // POST
        DELETE_MISSION : 'prayforall/blessing-mission/delete-mission/', // DELETE
    },

    ACTIVITIES : {
        GET_ACTIVITIES : 'prayforall/activities-slider' // GET
    },

    NOTIFICATION : {
        GET_NOTIFICATION_DOT : 'prayforall/notification-dot' // GET
    },

    PRAY_FOR_ALL_REPORT : {
        ORGANIZATION_REPORT : "prayforall/report/organization/", // GET
        PMD_SUMMARY_REPORT : "prayforall/report/pmd-summary/", // GET
        PMD_ORGANIZATION_REPORT : "prayforall/report/pmd-organizations/", // GET
        PMD_INVIDIVUAL_REPORT : "prayforall/report/pmd-individuals/", // GET
    },

    GLOBAL_PRAYER_ROOM : {
        // Top Banner
        NEXT_ROOM_OPEN: 'global_prayer_room/next_prayer_room_open', // GET
        TOP_BANNER_STATS: 'global_prayer_room/statistics', // GET

        // Plans 
        LIST_PLAN : 'global_prayer_room/list/subscription-plan', // GET
        LIST_PLAN_BY_USER : 'global_prayer_room/list-plan-by-user', // GET
        LISTPERSONAL_PLANS: 'global_prayer_room/list_subscription_plan/personel', // GET
        LISTCHURCH_PLANS: 'global_prayer_room/list_subscription_plan/personel', // GET
        SELECT_PERSONAL_PLAN: 'global_prayer_room/subscription-plan/personel', // POST
        PRODUCT_BY_PLAN_ID : (planID : string) => `global_prayer_room/subscription-plan/${planID}`, // GET
        USER_PLAN : 'global_prayer_room/subscription/user-plan', // GET
        SELECT_PLAN : 'global_prayer_room/select/subscription-plan', // GET
        UPDATE_USER_PLAN_STATUS : (planID : number, is_personal : number) => `global_prayer_room/subscription/update-user-plan/${planID}/${is_personal}`, // PUT
        

        // For meeting
        CONNECTED_USER : 'global_prayer_room/connected-user', // POST
        OPEN_ROOM : 'global_prayer_room/meeting/open_room/', // {roomId}
        CLOSE_ROOM : 'global_prayer_room/meeting/close_room/', // {roomId}
        JOIN_ROOM : 'global_prayer_room/user/join-room', // POST
        GET_ROOM_BY_ID : 'global_prayer_room/get-room-info-by-id', // GET
        GET_USER_CONNECTED_BY_ROOM : 'global_prayer_room/users/get-all-user-by-room', // GET
        USER_STATUS : 'global_prayer_room/user-status/by-room/', // {roomID} GET
        UPDATE_USER_STATUS : 'global_prayer_room/update-user-room', // PUT
        GET_USER_BY_ROOM_AND_PEER : 'global_prayer_room/room/user', // GET

        // For the room
        SEARCH_ROOMS: 'global_prayer_room/lists',
        PERSONAL_ROOMS: 'global_prayer_room/personel/rooms',
        CREATE_PERSONAL_ROOM: 'global_prayer_room/create/personel',
        DETAIL_PERSONAL_ROOM: 'global_prayer_room/details/personel/', // GET {id}
        UPDATE_PERSONAL_ROOM: 'global_prayer_room/update/personel/', // GET {id}
        CURRENT_PLAN_PERSONAL: 'global_prayer_room/subscription-plan/personel', // POST

        CHURCH_ROOMS: 'global_prayer_room/church/rooms',
        CREATE_CHURCH_ROOM: 'global_prayer_room/create/church',
        DETAIL_CHURCH_ROOM: 'global_prayer_room/details/personel/', // GET {id}
        UPDATE_CHURCH_ROOM: 'global_prayer_room/update/church/', // GET {id}
        
        PERSONAL_ROOM_USED: 'global_prayer_room/room_used', 
        CHURCH_ROOM_USED: 'global_prayer_room/church/room_used', 

        SELECT_ROOM: (roomID: number) => `global_prayer_room/select_room/${roomID}`, // POST

        // IN APP PURCHASE - SUBSCRIPTION
        IAP_SUBSCRIPTION_VALIDATE : 'plan-purchase/validate', // POST
        ADD_IAP_USER_ID : 'plan-purchase/update', // PUT
    },  

    /* GIVING*/
    GIVING : {
        LIST_FUNDS : 'giving/list_of_funds', // GET
        CREATE_FUND : 'giving/create_fund', // POST
        DETAIL_FUND : 'giving/fund_details', // GET
        STATISTICS_DONATION : (id : number) => `giving/statistic/${id}/donation`, // GET
        EDIT_FUND : (id : number) => `giving/update_fund/${id}`,  // PUT
        CLOSE_FUND : (id : number) => `giving/close_fund/${id}`,  // PUT
        DELETE_MEDIA_FUND : (mediaID : number ) => `giving/delete_media/${mediaID}`,
        
        HISTORY : 'giving/transaction_history', // GET
        DONATION_PAYMENT_PAYPAL : (fundId : number) => `giving/donation/${fundId}`, // POST
        DONATION_SUBSCRIPTION_UPDATE : 'giving/update_subscription', // POST
        DONATION_ONEOFF_UPDATE : 'giving/update_oneoff_donation', // POST
    }
}