import { Pipe, PipeTransform } from '@angular/core';
import { GoogleTranslateService } from '../../services/google-translate/google-translate.service';

@Pipe({
  name: 'canBeTranslated',
  standalone: true
})
export class CanBeTranslatedPipe implements PipeTransform {
  constructor(
    private gTranslate: GoogleTranslateService
  ) {}

  async transform(value: string, language_code: string = localStorage.getItem('currentLang') as string): Promise<boolean> {
    let currentLang = localStorage.getItem('currentLang') as string;
    if (language_code !== currentLang) {
      return true;
    }
    try {
      let langs = await this.gTranslate.detectLanguage(value);
      return langs[0] !== currentLang;
    } catch (error) {
      return language_code !== currentLang;
    }
  }

}
