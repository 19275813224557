import { Injectable } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { environment } from 'src/environments/environment';
import { APIROUTES } from 'src/app/shared/constants';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrayerBoardService {

  constructor(
    private baseService: BaseService
  ) { }

  get(prayerBoard: number){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERBOARD.DETAILS + prayerBoard).pipe(catchError(this.errorHandler));
  }

  getPrayerBoardCards(action: 'seen'|'unseen' = 'unseen', offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERBOARD.LIST + action, {offset}).pipe(catchError(this.errorHandler));
  }

  createAPrayerBoardCard(body: any){
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERBOARD.CREATE, body).pipe(catchError(this.errorHandler));
  }

  editAPrayerBoardCard(prayerboard: number, body: any){
    return this.baseService.put(environment.baseUrl + APIROUTES.PRAYERBOARD.UPDATE + prayerboard, body).pipe(catchError(this.errorHandler));
  }

  performPrayerBoardAction(id: number, action: string){
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERBOARD.PERFORM_ACTION(id, action), {}).pipe(catchError(this.errorHandler));
  }

  getPendingPrayerBoards(offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERBOARD.PENDING, {offset}).pipe(catchError(this.errorHandler));
  }

  getApprovedPrayerBoards(offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERBOARD.LISTAPPROVED, {offset}).pipe(catchError(this.errorHandler));
  }

  approvePrayerBoard(prayerboard: number){
    return this.baseService.put(environment.baseUrl + APIROUTES.PRAYERBOARD.APPROVE(prayerboard), {}).pipe(catchError(this.errorHandler));
  }

  rejectPrayerBoard(prayerboard: number){
    return this.baseService.put(environment.baseUrl + APIROUTES.PRAYERBOARD.REJECT(prayerboard), {}).pipe(catchError(this.errorHandler));
  }

  joinPrayerBoard(id: number){
    return this.performPrayerBoardAction(id, 'join');
  }

  unjoinPrayerBoard(id: number){
    return this.performPrayerBoardAction(id, 'unjoin');
  }

  likePrayerBoard(id: number){
    return this.performPrayerBoardAction(id, 'like');
  }

  unlikePrayerBoard(id: number){
    return this.performPrayerBoardAction(id, 'unlike');
  }

  movePrayerBoardToSeen(id: number){
    return this.performPrayerBoardAction(id, 'seen');
  }

  movePrayerBoardToUnseen(id: number){
    return this.performPrayerBoardAction(id, 'unseen');
  }

  addComment(prayerboard: number, content: string) {
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERBOARD.COMMENTS.ADD(prayerboard), {content}).pipe(catchError(this.errorHandler));
  }

  listComments(prayerboard: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERBOARD.COMMENTS.LIST(prayerboard), {offset}).pipe(catchError(this.errorHandler));
  }

  updateComment(prayerboard: number, commentId: number, content: string) {
    return this.baseService.put(environment.baseUrl + APIROUTES.PRAYERBOARD.COMMENTS.UPDATE(prayerboard, commentId), {content}).pipe(catchError(this.errorHandler));
  }

  deleteComment(prayerboard: number, commentId: number) {
    return this.baseService.delete(environment.baseUrl + APIROUTES.PRAYERBOARD.COMMENTS.UPDATE(prayerboard, commentId)).pipe(catchError(this.errorHandler));
  }

  reportComment(prayerboard: number, body: {prayer_board_comment_id: number, reason: string}) {
    return this.baseService.post(environment.baseUrl + APIROUTES.PRAYERBOARD.COMMENTS.REPORT(prayerboard), body).pipe(catchError(this.errorHandler));
  }

  listReportedComments(prayerboard: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERBOARD.COMMENTS.LIST_REPORTED(prayerboard), {offset}).pipe(catchError(this.errorHandler));
  }

  createPrayerPointByPrayerBord(body: any){
    return this.baseService.get(environment.baseUrl + APIROUTES.PRAYERBOARD.CREATE).pipe(catchError(this.errorHandler));
  }
  

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }
}