import { Injectable, inject } from '@angular/core';
import { TranslateParser, TranslateStore } from '@ngx-translate/core';

@Injectable()
export class LaravelLikeParserService extends TranslateParser {
  private translateStore: TranslateStore = inject(TranslateStore);
  // Override the interpolate method to handle function expressions and replace :name with the actual value
  interpolate(expr: string | Function, params?: any): string {
    if (typeof expr === 'function') {
      return this.safeExecuteFunction(expr, params);
    } else if (params && typeof expr === 'string') {
      for (const key of Object.keys(params)) {
        const pattern = new RegExp(':' + key, 'g');
        expr = expr.replace(pattern, params[key]);
      }
    }
    return expr;
  }

  // Override the getValue method to handle nested keys and fallback to default language
  getValue(target: any, key: string): any {
    if (!target || typeof target !== 'object' || !key) {
      return key;
    }

    const keys = key.split('.');
    let value = target;

    for (const k of keys) {
      if (value.hasOwnProperty(k)) {
        value = value[k];
      } else {
        // If the key is not found in the current language, try to fetch from the default language
        const defaultValue = this.getDefaultValue(key);
        return defaultValue;
      }
    }

    return value;
  }

  // Helper method to get the default value for a key
  private getDefaultValue(key: string): any {
    const currentLang = this.translateStore.currentLang;
    const defaultLang = this.translateStore.defaultLang;
    let target = this.translateStore.translations[defaultLang];

    if (currentLang !== defaultLang) {
      // Check if the key exists in the default language
      const defaultKeys = key.split('.');
      let defaultValue = target;

      for (const k of defaultKeys) {
        if (defaultValue.hasOwnProperty(k)) {
          defaultValue = defaultValue[k];
        } else {
          return key;
        }
      }

      return defaultValue;
    }

    return key;
  }

  // Helper method to safely execute a function and handle potential errors
  private safeExecuteFunction(fn: Function, params?: any): string {
    try {
      return fn(params);
    } catch (error) {
      console.error('Error executing translation function:', error);
      return '';
    }
  }
}