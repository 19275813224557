import { Injectable } from '@angular/core';
import { BaseService } from '../base/base.service';
import { environment } from 'src/environments/environment';
import { catchError, of } from 'rxjs';
import { APIROUTES } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor(
    private baseService: BaseService
  ) { }

  listOrganizationMembers(organization: number) {
    return this.baseService.get(environment.baseUrl + APIROUTES.ORGANIZATION.MEMBERS(organization)).pipe(catchError(this.errorHandler));
  }

  listMembersForEmpewerment(offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIROUTES.ORGANIZATION.USERLIST, {offset}).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }
}
